import axios from "axios";
import SecureLS from "secure-ls"

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 12000,
  xsrfHeaderName: "X-XSRF-TOKEN", 
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

ax.interceptors.request.use(
  config => {
	var ls = new SecureLS({encodingType: 'aes'});
    var data = ls.get('tsl')
    config.headers["Authorization"] = 'Bearer ' + data.token
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

export default ax;
